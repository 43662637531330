<!--
 * @Author: wh
 * @Date: 2020-08-17 16:56:59
 * @LastEditTime: 2020-11-16 19:46:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\statisticalAnalysis\salesStatistics\dayData.vue
-->
<template>
    <div class="dayData">
        <div class="wrapper">
            <div class="header-wrapper">
                <span>选择时间段</span>
                <el-date-picker
                    v-model="value1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    class="time"
                    value-format="yyyy-MM-dd"
                    @change="search"
                >
                </el-date-picker>
                <el-select
                    v-model="station"
                    placeholder="请选择"
                    @change="search"
                >
                    <el-option
                        v-for="item in stations"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span class="search-btn" @click="search">查询</span>
            </div>
            <div class="main">
                <!-- 色块 -->
                <div class="color-block clearfix">
                    <div class="left block-item" @click="dataDrilling('mrsj_xsfl')">
                        <p class="dgfont" :title="statistic.xsfl">
                            {{ statistic.xsfl }}
                        </p>
                        <p class="discribe">
                            销售方量
                        </p>
                    </div>
                    <div class="right">
                        <div class="right-top">
                            <div class="right-top1 block-item" @click="dataDrilling('mrsj_fhfl')">
                                <p class="dgfont" :title="statistic.fhfl">
                                    {{ statistic.fhfl }}
                                </p>
                                <p class="discribe">
                                    发货方量
                                </p>
                            </div>
                            <div class="right-top2">
                                <div class="top">
                                    <div class="left-top">
                                        <div class="item block-item" @click="dataDrilling('mrsj_ddpdfl')">
                                            <p class="dgfont font50" :title="statistic.ddpdl">
                                                {{ statistic.ddpdl }}
                                            </p>
                                            <p class="discribe">
                                                调度派单方量
                                            </p>
                                        </div>
                                        <div class="item block-item" @click="dataDrilling('mrsj_scfl')">
                                            <p class="dgfont font50" :title="statistic.scfl">
                                                {{ statistic.scfl }}
                                            </p>
                                            <p class="discribe">
                                                生产方量
                                            </p>
                                        </div>
                                        <div class="item block-item" @click="dataDrilling('mrsj_ccgbl')">
                                            <p class="dgfont font50" :title="statistic.ccgbl">
                                                {{ statistic.ccgbl }}
                                            </p>
                                            <p class="discribe">
                                                出场过磅量
                                            </p>
                                        </div>
                                    </div>
                                    <div class="cfcon">
                                        <div class="sccf block-item" @click="dataDrilling('mrsj_sccf')">
                                            <p class="dgfont font50" :title="statistic.sccf">
                                                {{ statistic.sccf }}
                                            </p>
                                            <p class="discribe">
                                                生产差方
                                            </p>
                                        </div>
                                        <div class="gbcf block-item" @click="dataDrilling('mrsj_gbcf')">
                                            <p class="dgfont font50" :title="statistic.gbcf">
                                                {{ statistic.gbcf }}
                                            </p>
                                            <p class="discribe">
                                                过磅差方
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom">
                                    <div class="bottom-item block-item" @click="dataDrilling('mrsj_sjxlfl')">
                                        <p class="dgfont font40" :title="statistic.sjqsfl">
                                            {{ statistic.sjqsfl }}
                                        </p>
                                        <p class="discribe">
                                            司机卸料方量
                                        </p>
                                    </div>
                                    <div class="bottom-item block-item" @click="dataDrilling('mrsj_khqsfl')">
                                        <p class="dgfont font40" :title="statistic.khqsfl">
                                            {{ statistic.khqsfl }}
                                        </p>
                                        <p class="discribe">
                                            客户签收方量
                                        </p>
                                    </div>
                                    <div class="bottom-item block-item" @click="dataDrilling('mrsj_xscf')">
                                        <p class="dgfont font40" :title="statistic.xscf">
                                            {{ statistic.xscf }}
                                        </p>
                                        <p class="discribe">
                                            销售差方
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-bottom">
                            <div class="item block-item" @click="dataDrilling('mrsj_ttfl')">
                                <div class="bottom-position">
                                    <p class="dgfont font40" :title="statistic.ttfl">
                                        {{ statistic.ttfl }}
                                    </p>
                                    <p class="discribe">
                                        剩退方量
                                    </p>
                                </div>
                            </div>
                            <div class="item block-item" @click="dataDrilling('mrsj_zffl')">
                                <div class="bottom-position">
                                    <p class="dgfont font40" :title="statistic.zffl">
                                        {{ statistic.zffl }}
                                    </p>
                                    <p class="discribe">
                                        转发方量
                                    </p>
                                </div>
                            </div>
                            <div class="item block-item" @click="dataDrilling('mrsj_pyfl')">
                                <div class="bottom-position">
                                    <p class="dgfont font40" :title="statistic.pyfl">
                                        {{ statistic.pyfl }}
                                    </p>
                                    <p class="discribe">
                                        盘盈方量
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 统计图 -->
                <div class="chart-con">
                    <h4>砼方量</h4>
                    <div id="barChart" class="barChart"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {},
    data() {
        return {
            // 时间范围
            value1: [],
            // 选中场站
            station: '',
            // 场站列表
            stations: [],
            // 统计数
            statistic: {},
            // 图表数据
            chartData: {},
            legendData: [],
            saleData: [],
            deliverData: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        // 初始化
        init() {
            const nowDate = util.currentTimeFormat('yyyy-MM-dd');
            this.value1.push(nowDate);
            this.value1.push(nowDate);
            this.getStation();
            this.getMrsjtj();
            this.getChartData();
        },
        // 查询
        search() {
            this.getMrsjtj();
            this.getChartData();
        },
        // 拉取场站
        async getStation() {
            const res = await this.getStationApi();
            if (res) {
                this.stations = res;
                this.stations.unshift({ id: '', name: '全部' });
            }
        },
        // 每日数据统计
        async getMrsjtj() {
            const res = await this.getMrsjtjApi();
            if (res) {
                this.statistic = res;
                // 生产差方=调度派单量-生产方量
                // eslint-disable-next-line radix
                this.statistic.sccf = (Number(res.ddpdl) - Number(res.scfl)).toFixed(2);
                // 过磅差方=发货方量-出场过磅量
                // eslint-disable-next-line radix
                this.statistic.gbcf = (Number(res.fhfl) - Number(res.ccgbl)).toFixed(2);
                // 销售差方=销售方量-发货方量
                // eslint-disable-next-line radix
                this.statistic.xscf = (Number(res.xsfl) - Number(res.fhfl)).toFixed(2);
            }
        },
        // 图表数据
        async getChartData() {
            const res = await this.getChartDataApi();
            if (res) {
                this.chartData = res;
                const legendData = [];
                const saleData = [];
                const deliverData = [];
                res.map(item => {
                    legendData.push(item.name);
                    saleData.push(item.xsfl);
                    deliverData.push(item.fhfl);
                });
                this.legendData = legendData;
                this.saleData = saleData;
                this.deliverData = deliverData;
                this.drawChart();
            }
        },
        // 数据钻取
        dataDrilling(tableCode) {
            this.$toast(
                {
                    title: true,
                    text: '',
                    type: 'eject',
                    t_url: 'tableList/index',
                    width: '15.6rem',
                    extr: {
                        code: {
                            TableCode: tableCode,
                            QueryString: 'station_id=' + this.station + '&start_time=' + this.value1[0] + '&end_time=' + this.value1[1],
                        },
                    },
                }
            );
        },

        getMrsjtjApi() {
            const queryStr = '?station_id=' + this.station + '&start_time=' + this.value1[0] + '&end_time=' + this.value1[1];
            const path = '/interfaceApi/report/tjfx/mrsj/tjs' + queryStr;
            return this.$axios.$get(path, { defEx: true });
        },
        getChartDataApi() {
            const queryStr = '?station_id=' + this.station + '&start_time=' + this.value1[0] + '&end_time=' + this.value1[1];
            const path = '/interfaceApi/report/tjfx/mrsj/xsfhfldb/abh' + queryStr;
            return this.$axios.$get(path, { defEx: true });
        },
        getStationApi() {
            const path = '/interfaceApi/report/salesanalysis/station';
            return this.$axios.$get(path, { defEx: true });
        },
        // 折线图
        drawChart() {
            const myChart = this.$echarts.init(document.getElementById('barChart'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [10, 0, 0, 0],
                },
                grid: {
                    top: '18%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        triggerEvent: true,
                        data: this.legendData,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: '销售方量',
                        type: 'bar',
                        barWidth: '25',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#55A7FE', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#3E79C5', // 100% 处的颜色
                                }], false),
                            },
                        },
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '16',
                            padding: 5,
                            color: '#3E79C5',
                        },
                        data: this.saleData,
                    },
                    {
                        name: '发货方量',
                        type: 'bar',
                        barWidth: '25',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#EFAB54', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#FA8C47', // 100% 处的颜色
                                }], false),
                            },
                        },
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '16',
                            padding: 5,
                            color: '#FA8C47',
                        },
                        data: this.deliverData,
                    },
                ],
            };
            myChart.setOption(option, true);
        },
    },
};
</script>
<style lang="stylus">
.dayData
    .wrapper
        height 100%
        background #fff
        padding .2rem
        .header-wrapper
            margin-bottom .2rem
            .time
                margin 0 .1rem
                vertical-align middle
            .search-btn
                padding: 0 0.1rem;
                height .36rem
                background #2978ff
                border-radius .04rem
                display inline-block
                color #ffffff
                text-align center
                line-height .36rem
                margin-left .1rem
                cursor pointer
            span
                vertical-align middle
            .el-select
                vertical-align middle
        .main
            height calc(100% - .56rem)
            display flex
            padding-top .2rem
            padding-bottom: .2rem;
            .color-block
                cursor pointer
                flex-shrink 0
                flex 1
                margin-right 1%
                width 54%
                .block-item
                    &:hover
                        box-shadow .06rem .06rem 2rem 0 rgba(0,0,0,0.6)
                .dgfont
                    font-family technology
                    font-size .68rem
                    color #fff
                    white-space nowrap
                    text-overflow ellipsis
                    overflow hidden
                    padding 0 .1rem
                    display flex
                    justify-content center
                    align-items center
                .discribe
                    font-size .18rem
                    color #fff
                .font40
                    height 0.4rem
                    font-size .4rem
                .font50
                    height 0.5rem
                    font-size .5rem
                .left
                    width 2rem
                    background linear-gradient(314deg,rgba(239,171,84,1) 0%,rgba(250,140,71,1) 100%)
                    border-radius .08rem
                    float left
                    margin-right .1rem
                    padding-top 35%
                    text-align center
                    height 100%
                .right
                    float left
                    text-align center
                    width calc(100% - 2.1rem)
                    height 100%
                    .right-top
                        display flex
                        height 4.66rem
                        margin-bottom .05rem
                        .right-top1
                            width 1.8rem
                            height 100%
                            background linear-gradient(315deg,rgba(85,167,254,1) 0%,rgba(62,121,197,1) 100%)
                            border-radius .08rem
                            flex-shrink 0
                            margin-right .05rem
                            padding-top 35%
                        .right-top2
                            flex-shrink 0
                            width calc(100% - 1.85rem)
                            .top
                                display flex
                                .left-top
                                    flex 1
                                    flex-shrink 0
                                    width calc(50% - .05rem)
                                    .item
                                        background linear-gradient(132deg,rgba(57,154,189,1) 0%,rgba(75,189,230,1) 100%)
                                        height 1.2rem
                                        border-radius .08rem
                                        margin 0 .05rem .05rem 0
                                        padding-top 10%
                                .cfcon
                                    flex 1
                                    flex-shrink 0
                                    margin-right .05rem
                                    width calc(50% - .05rem)
                                    .sccf
                                        background linear-gradient(132deg,rgba(57,154,189,1) 0%,rgba(75,189,230,1) 100%)
                                        border-radius .08rem
                                        margin 0 .05rem .05rem 0
                                        padding-top 35%
                                        height 2.45rem
                                        width 100%
                                    .gbcf
                                        background linear-gradient(317deg,rgba(113,198,176,1) 0%,rgba(78,164,150,1) 100%)
                                        border-radius .08rem
                                        margin 0 .05rem .05rem 0
                                        padding-top 10%
                                        height 1.2rem
                                        width 100%
                            .bottom
                                display flex
                                .bottom-item
                                    flex 1
                                    flex-shrink 0
                                    width calc(33.333% - .05rem)
                                    background linear-gradient(317deg,rgba(113,198,176,1) 0%,rgba(78,164,150,1) 100%)
                                    border-radius .08rem
                                    margin 0 .05rem .05rem 0
                                    padding-top .12rem
                                    height .91rem
                    .right-bottom
                        display flex
                        height calc(100% - 4.71rem)
                        .item
                            flex-shrink 0
                            flex 1
                            width calc(33% - .05rem)
                            height 100%
                            background linear-gradient(132deg,rgba(57,154,189,1) 0%,rgba(75,189,230,1) 100%)
                            border-radius .08rem
                            margin-right .05rem
                            position relative
                            .bottom-position
                                position absolute
                                left 0
                                top 50%
                                width 100%
                                margin-top -.27rem
            .chart-con
                height 100%
                border 1px solid #D7D7D7
                position relative
                flex-shrink 0
                flex 1
                width 45%
                .barChart
                    margin-top .2rem
                    height calc(100% - .2rem)
                h4
                    position absolute
                    left .3rem
                    top .1rem
</style>
